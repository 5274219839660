import React from 'react';

import SectionHero from '../components/Block/SectionHero';
import SectionBlock from '../components/Block/SectionBlock';

const sections = [
  {
    img: '/way/agility.jpg',
    text: [
      {
        title: 'Agility',
        description:
          '<p>We work fast while efficiently responding to the unpredictable. Our teams and processes are tailored ' +
          'for your project’s success.</p>' +
          '<ul>' +
          '<li>' +
          '<h4>Cross-functional Teams</h4>' +
          '<p>With the skills and knowledge of our developers, we are able to assemble project teams that can deal ' +
          'with all technology needs of the project</p>' +
          '</li>' +
          '<li>' +
          '<h4>Iterative Development</h4>' +
          '<p>We use short development cycles, frequent feedback loops and efficient CI/CD pipelines. ' +
          'Your product’s time to market is always on our mind</p>' +
          '</li>' +
          '<li>' +
          '<h4>Flexibility</h4>' +
          '<p>We don’t restrict ourselves to just one project management approach - we use Scrum, Kanban or ' +
          'traditional waterfall methods - whatever works best with the specifics and size of your project</p>' +
          '</li>' +
          '</ul>',
      },
    ],
  },
  {
    img: '/way/remote.jpg',
    text: [
      {
        title: 'Effective Remote Cooperation',
        description:
          '<p>Even if we are geographically distant or the times don’t allow for frequent face-to-face interactions, ' +
          'we have means to reach common objectives.</p>' +
          '<ul>' +
          '<li>' +
          '<h4>Visibility</h4>' +
          '<p>You have full visibility into our planning, executing and controlling processes: from initial ' +
          'requirements analysis and solution estimation to risk management and budget control</p>' +
          '</li>' +
          '<li>' +
          '<h4>Tools</h4>' +
          '<p>We use industry-standard tools supporting effective remote cooperation in all key areas of our ' +
          'operation: software development, project management and communication</p>' +
          '</li>' +
          '<li>' +
          '<h4>Project Management</h4>' +
          '<p>We assign a dedicated Agile Project Manager focused on your project’s success. The person in this ' +
          'role supports and coordinates the work of the whole project team, connecting everyone involved on your and our end</p>' +
          '</li>' +
          '</ul>',
      },
    ],
  },
  {
    img: '/way/partnership.jpg',
    text: [
      {
        title: 'Focus on results',
        description:
          '<p>We strive to reach common objectives. When we meet challenges we face them together.</p>' +
          '<ul>' +
          '<li>' +
          '<h4>Innovation</h4>' +
          '<p>We foster a culture of innovation. We choose the tools and technologies to build the optimal solution ' +
          'for your business and we keep on improving</p>' +
          '</li>' +
          '<li>' +
          '<h4>Predictability</h4>' +
          '<p>Our structure ensures fast project start-up and reliable results. Recruiting talent and sharing ' +
          'knowledge within our core domains of expertise allow us to maintain project continuity and scalabilit</p>' +
          '</li>' +
          '<li>' +
          '<h4>Transparent Communication</h4>' +
          '<p>We believe mutual trust is built upon open communication</p>' +
          '</li>' +
          '</ul>',
      },
    ],
  },
];

const TheWayWeWorkPage = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">The way we work</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SectionHero noCapitalize>
      Our work is based on three pillars:
      <span className="th-theme-text-color"> Agility</span>,
      <span className="th-theme-text-color"> Effective Remote Cooperation</span> and
      <span className="th-theme-text-color"> Focus on Results</span>
    </SectionHero>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFull imgFirst noPadding {...sections[0]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFull imgFirst noPadding {...sections[1]} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFull imgFirst {...sections[2]} />
  </>
);

export default TheWayWeWorkPage;
