import React from 'react';

import '../../styles/theme-text.scss';
import './SectionHero.scss';

const SectionHero = ({ noCapitalize = false, children = 'Lorem ipsum...' }) => {
  return (
    <section className="th-section-hero">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <h2 className="th-theme-title-large" style={{ textTransform: noCapitalize ? 'none' : 'capitalize' }}>
              {children}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
